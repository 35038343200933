import {graphql, Link} from 'gatsby';
import React from 'react';

import {getFlagEmoji, MongoPassport} from '@travelfreedom/shared';

import {
  buildMapDataFromVisaLists,
  Layout,
  TableListEntry,
  PassportImage,
  PassportImageData,
  ReportErrorButton,
  SEO,
  WorldMap,
} from '../components';
import {Passport, VisaLists} from '../library/common';

interface NationalIdProps {
  data: {
    mongodbTravelfreedomPassports: MongoPassport;
    contentfulPassport: {
      images: {
        localFile: {
          childImageSharp: PassportImageData;
        }[];
      };
    };
  };
}

export const NationalIdTemplate = ({data}: NationalIdProps): JSX.Element => {
  const passport = Passport.fromJson(data.mongodbTravelfreedomPassports);

  return (
    <Layout>
      <SEO
        title={`${getFlagEmoji(passport.code)} ${Passport.adjectiveName(passport)}`}
        pathname={Passport.hrefPath(passport)}
      />
      <h1 className="font-bold text-gray-500 mt-3 mb-2 max-w-7xl mx-auto">
        {getFlagEmoji(passport.code)} {Passport.adjectiveName(passport)}
      </h1>

      <div className="flex p-2 border rounded border-gray-300 bg-white min-w-0">
        {data.contentfulPassport?.images && (
          <PassportImage
            smallImage={data.contentfulPassport.images[0].localFile.childImageSharp.smallImage}
            largeImage={data.contentfulPassport.images[0].localFile.childImageSharp.largeImage}
            passport={passport}
            format="HORIZONTAL"
          />
        )}
        <div className="inline-flex flex-col">
          <div className="my-1">
            {Passport.adjectiveName(passport)} lets you travel to{' '}
            {VisaLists.travelfreedom(passport)} countries/territories.
          </div>
          <div className="my-1">
            Since you cannot get a visa on a national ID, you cannot use it to travel to any
            territories besides those who let you in without a visa, i.e. you cannot travel to gray
            areas on the map.
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 max-w-7xl mx-auto">
        <div className="inline-flex flex-wrap py-2">
          <Link
            to={`/compare?compare=${passport.globalCode.toLowerCase()}`}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-2"
          >
            Compare
          </Link>
          <ReportErrorButton />
        </div>
      </div>

      <div className="bg-white my-2 max-w-7xl mx-auto">
        <WorldMap worldMapData={buildMapDataFromVisaLists(passport)} showLegend />
      </div>

      {/* <GoogleAd /> */}

      {passport.visafreeList.length > 0 && (
        <table className="table-auto w-full max-w-7xl mx-auto mb-2 bg-white">
          <thead>
            <tr className="bg-green-600 text-white">
              <th colSpan={2} className="p-2">
                Visa-Free/Visa-on-arrival
              </th>
            </tr>
          </thead>
          <tbody className="bg-green-100 divide-y divide-gray-200">
            {passport.visafreeList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={passport.conditions[code]}
                targetType="destination"
              />
            ))}
          </tbody>
        </table>
      )}
      {passport.etaList.length > 0 && (
        <table className="table-auto w-full max-w-7xl mx-auto mb-2 bg-white">
          <thead>
            <tr className="bg-yellow-600 text-white">
              <th colSpan={2} className="p-2">
                Electronic Authorization
              </th>
            </tr>
          </thead>
          <tbody className="bg-yellow-100 divide-y divide-gray-200">
            {passport.etaList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={passport.conditions[code]}
                targetType="destination"
              />
            ))}
          </tbody>
        </table>
      )}
      {passport.visapriorList.length > 0 && (
        <table className="table-auto w-full max-w-7xl mx-auto mb-2 bg-white">
          <thead>
            <tr className="bg-red-600 text-white">
              <th colSpan={2} className="p-2">
                Visa required prior to arrival
              </th>
            </tr>
          </thead>
          <tbody className="bg-red-200 divide-y divide-gray-200">
            {passport.visapriorList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={passport.conditions[code]}
                targetType="destination"
              />
            ))}
          </tbody>
        </table>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!, $globalCode: String!) {
    mongodbTravelfreedomPassports(fields: {slug: {eq: $slug}}) {
      globalCode
      name
      adjective
      ranking {
        rank
        rankArea
        rankPopulation
        travelfreedom
        travelfreedomArea
        travelfreedomPopulation
      }
      visaList {
        visaprior
        visafree
        eta
        banned
      }
      conditions {
        key
        value
      }
    }
    contentfulPassport(globalCode: {eq: $globalCode}) {
      images {
        localFile {
          childImageSharp {
            smallImage: gatsbyImageData(width: 150, height: 100)
            largeImage: gatsbyImageData
          }
        }
      }
    }
  }
`;

export default NationalIdTemplate;
